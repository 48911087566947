<template>
  <div
    :class="[
      'content',
      isTurnHappening ? 'animate-fire' : '',
      showPlayers ? '' : 'dark-mode',
    ]"
  >
    <div class="bg"></div>
    <video :class="['fire']" autoplay muted loop>
      <source src="../../assets/fire-burning.mp4" type="video/mp4" />
    </video>
    <div v-if="isTurnHappening" class="burning-dcar">
      <h3>
        <InlineLoading class="loading" /> Burning {{ dcarAmountToBurn }} DCAR
      </h3>
    </div>
    <template v-if="!isTurnHappening">
      <div
        :class="[
          showPlayers ? 'white-box' : 'black-box',
          'intro',
          collapseIt ? 'collapse' : 'not-collapsed',
          'show-scale',
        ]"
        v-if="!showPlayers && showPage"
        @click="collapseIt = !collapseIt"
      >
        <h1>Final Hour Of Battle!</h1>
        <div>
          <p class="fade-in">
            As the sun sets, the battlefield turns black, veiling the
            competitors’ performances. A mysterious battle unfolds in the
            obscurity, where warriors clash without knowledge of their
            adversaries’ feats.
          </p>
          <p class="fade-in">
            In this realm of uncertainty, legends are born. Embrace the
            challenge, navigate through the shadows, and emerge as the ultimate
            champion! Join the burn battle in the dark and seize victory!
          </p>
        </div>
      </div>
      <div
        :class="[
          showPlayers ? 'white-box' : 'black-box',
          'rewards',
          'fade-in-2',
          collapseIt2 ? 'collapse' : 'not-collapsed',
        ]"
        v-if="showPage"
        @click="collapseIt2 = !collapseIt2"
      >
        <h1>Win a Steam Deck, Legendary Hero and Allowlist!</h1>
        <div>
          <img src="../../assets/rewards.png" alt="dcar battle reward" />
        </div>
      </div>
      <div v-if="hasStarted">
        <div class="black-box details fade-in-2">
          Ends in
          <CountdownTimer
            :timeLeft="60"
            @timeIsRunningOut="loadPage('time-running-out')"
            @timeLeft="loadPage('time-left')"
            v-if="roundEndTime != 0"
            :duration="roundEndTime"
          />
        </div>
        <div
          :class="[
            showPlayers ? 'white-box' : 'black-box',
            'burn-dcar',
            'fade-in-2',
          ]"
        >
          <h4>Burn DCAR To Secure Your Spot</h4>
          <div class="grid">
            <div class="balances col">
              <h5>Your DCAR Balance</h5>
              <div class="dcar" id="dcar-balance">
                <template v-if="!isFetchingDcarBalance">
                  {{ dcarBalance }}
                </template>
                <template v-else>
                  <SkeletalLoading width="100" height="20" />
                </template>
              </div>
            </div>
            <div class="input-amount col">
              <h5>Enter DCAR amount to burn</h5>
              <input
                type="number"
                id="dcar-amount-to-burn"
                name="dcar-amount"
                step="10"
                min="0"
                @input="checkAllowanceAgain"
                v-model="dcarAmountToBurn"
                :disabled="
                  isLoadingInitialData || isApprovingDCAR || !isSessionActive
                "
              />
              <p>minimum {{ minimumBurn }} DCAR</p>
            </div>
            <div class="burn-dcar-button">
              <BlueButton
                v-if="isDCARApproved"
                :disabled="
                  isBurningToken ||
                  !isSessionActive ||
                  isLoadingInitialData ||
                  dcarAmountToBurn === 0
                "
                :isLoading="isBurningToken"
                @click="burnDCAR"
                size="large"
                >Burn DCAR</BlueButton
              >
              <BlueButton
                v-else
                :disabled="
                  isLoadingInitialData || isApprovingDCAR || !isSessionActive
                "
                @click="approveDCAR"
                size="large"
                :isLoading="isApprovingDCAR"
                >Approve DCAR</BlueButton
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="showPage">
        <div class="black-box details fade-in-2">
          <h4 v-if="!isLoadingInitialData">Round Hasn't Started Yet!</h4>
        </div>
      </div>
      <div
        :class="['grid-overall', !showPlayers ? 'two-col' : '', 'fade-in-2']"
      >
        <div
          class="leaderboard top-burner white-box"
          v-if="overallTopBurner && showPlayers"
        >
          <h4>Overall Top Burner</h4>
          <div class="leaderboard-entry" v-if="overallTopBurner">
            <div class="user">
              {{ avvyNameForAddress(overallTopBurner.user) }}
            </div>
            <div class="balances">
              <div class="dcar">
                {{ overallTopBurner.amount }}
              </div>
            </div>
          </div>
          <div v-else><p>No Top Burner Yet</p></div>
        </div>
        <div
          :class="[
            showPlayers ? 'white-box' : 'black-box',
            'leaderboard',
            'fade-in-2',
          ]"
          v-if="overallTopBurner && showPage"
        >
          <h4>Your DCAR Burn Amount</h4>
          <div class="leaderboard-entry">
            <div class="user">
              {{ avvyNameForAddress(address) }}
            </div>
            <div class="balances">
              <div class="dcar">
                {{ yourBurnAmount }}
              </div>
            </div>
          </div>
        </div>
        <div
          :class="[
            showPlayers ? 'white-box' : 'black-box',
            'leaderboard',
            'fade-in-2',
          ]"
          v-if="overallTopBurner && showPage"
        >
          <h4>Total DCAR Burned this Round</h4>
          <div class="leaderboard-entry single-column">
            <div class="balances">
              <div class="dcar">
                {{ totalBurned }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="leaderboard leaderboard-list white-box"
        v-if="leaderboard && showPlayers"
      >
        <h4>DCAR Burn Battle Leaderboard (round {{ currentRound }})</h4>
        <template v-if="leaderboard.length > 0">
          <div
            class="leaderboard-entry"
            v-for="(entry, index) in leaderboard"
            :key="index"
          >
            <div class="rank">
              {{ index + 1 }}
            </div>
            <div class="user">
              {{ avvyNameForAddress(entry.user) }}
            </div>

            <div class="balances">
              <div class="dcar">
                {{ amountBurnedInDecimal(entry.amount) }}
              </div>
            </div>
          </div>
        </template>
        <div v-else>
          <h5 style="color: #000">
            No Entries In Round {{ currentRound }} Yet
          </h5>
          <br />
        </div>
      </div>
      <div v-if="!showPlayers"></div>
    </template>
  </div>
</template>

<script>
import { onMounted, ref, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Constants from "../../consts/constants";

// import GenericLoading from "../../components/LoadingComponents/GenericLoading.vue";
import SkeletalLoading from "../../components/LoadingComponents/SkeletalLoading.vue";
// import GenericLoading from "../../components/LoadingComponents/GenericLoading.vue";
import CountdownTimer from "../../components/CountdownTimer.vue";

import { ethers } from "ethers";
import { parseError } from "../../utils/helpers";
import { useUser } from "../../composables/user";
import { usePrice } from "../../composables/price";
import { useSound } from "../../composables/sound";
import fireSound from "../../assets/sound/fire.mp3";
import trumpetSound from "../../assets/sound/trumpet.mp3";

import InlineLoading from "../../components/LoadingComponents/InlineLoading.vue";

import {
  getDCARBurnBattleContract,
  getDCARContract,
} from "../../utils/getContract";
import BlueButton from "../../components/Buttons/BlueButton.vue";

export default {
  displayName: "MintGameBase",
  components: {
    // GenericLoading,
    BlueButton,
    SkeletalLoading,
    InlineLoading,
    CountdownTimer,
  },
  setup() {
    // Composables
    const store = useStore();
    const router = useRouter();
    const { address, signer } = useUser();
    const { getDCARBalance, dcarBalance } = usePrice();

    // Loading related
    const isLoading = ref(false);
    const isDCARApproved = ref(false);
    const isLoadingInitialData = ref(true);
    const isFetchingDcarBalance = ref(true);
    const isApprovingDCAR = ref(false);
    const isBurningToken = ref(false);
    const zeroAddress = ethers.constants.AddressZero;
    const currentAllowance = ref(0);
    const roundEndTime = ref(0);
    const roundStartTime = ref(null);
    const hasStarted = ref(false);
    const totalBurned = ref(0);
    const showPlayers = ref(false);
    const collapseIt = ref(false);
    const collapseIt2 = ref(false);
    const showPage = ref(false);

    // Reactive data variables
    const isLoggedIn = ref(false);
    const profileImage = ref("");
    const dcarAmountToBurn = ref(0);
    const minimumBurn = ref(0);
    const currentRound = ref(0);
    const leaderboard = ref(null);
    const overallTopBurner = ref(null);
    const yourBurnAmount = ref(0);

    // Contract reactive variables
    const DCARBurnBattleContract = ref(null);
    const DCARContract = ref(null);

    const playFireSound = useSound(fireSound, {
      volume: 1,
      interrupt: true,
      loop: true,
    });

    const playTrumpetSound = useSound(trumpetSound, {
      volume: 1,
      interrupt: true,
    });

    onMounted(async () => {
      // preloading sound files for better experience
      const fireSoundFile = new Audio(fireSound);
      const trumpetSoundFile = new Audio(trumpetSound);
      fireSoundFile.load();
      trumpetSoundFile.load();

      if (!hasStarted.value) {
        showPage.value = true;
        showPlayers.value = true;
      }

      /**
       * Initialising contract instances if signer is available
       */
      if (isSessionActive.value) {
        await initialiseContractsAndGetData();
        subscribeToDCARBurn();
      } else {
        await getDataViaRPC();
      }
    });

    onUnmounted(() => {
      unWatchSessionActive();
    });

    const loadPage = (type) => {
      if (type === "time-running-out") {
        showPlayers.value = false;
      } else if (type === "time-left") {
        showPlayers.value = true;
      }
      showPage.value = true;
    };

    const initialiseContractsAndGetData = async () => {
      isLoadingInitialData.value = true;
      if (isSessionActive.value) {
        // Initalising contract instances
        DCARBurnBattleContract.value = getDCARBurnBattleContract(signer.value);
        DCARContract.value = getDCARContract(signer.value);

        // Checking if DCAR approved or not
        await checkIsDCARApproved();

        // Getting DCAR Balance
        isFetchingDcarBalance.value = true;
        await getDCARBalance();
        isFetchingDcarBalance.value = false;

        // Setting minimum amount to burn as defined in contract
        const minBurn = await DCARBurnBattleContract.value.minimumBurn();
        console.log(minBurn);

        // TODO: ENSURE ITS ALWAYS IN FORMAT ETHER
        minimumBurn.value = ethers.utils.formatEther(minBurn);
        // if (minBurn > 100000) {
        // } else {
        //   minimumBurn.value = ethers.utils.formatUnits(minBurn, 0);
        // }
        dcarAmountToBurn.value = minimumBurn.value;

        // Getting the current round
        const curRound = await DCARBurnBattleContract.value.currentRound();
        currentRound.value = ethers.utils.formatUnits(curRound, 0);

        const roundDetails = await DCARBurnBattleContract.value.rounds(
          curRound
        );

        totalBurned.value = ethers.utils.formatEther(roundDetails.totalBurned);
        const duration = parseInt(
          ethers.utils.formatUnits(roundDetails.duration, 0)
        );
        roundStartTime.value = ethers.utils.formatUnits(
          roundDetails.startTime,
          0
        );
        roundEndTime.value =
          parseInt(roundStartTime.value) + parseInt(duration);
        const now = new Date();
        if (
          roundStartTime.value === 0 ||
          roundEndTime.value * 1000 < now.getTime()
        ) {
          hasStarted.value = false;
        } else {
          hasStarted.value = true;
        }
        await updateDCARBurnedByYou();
        // Loading leaderboard
        await fetchLeaderboard(DCARBurnBattleContract.value);
      }
      isLoadingInitialData.value = false;
    };

    /**
     * Fetch DATA using rpc provider instead of a signer
     */
    const getDataViaRPC = async () => {
      try {
        const rpcProvider = new ethers.providers.JsonRpcProvider(
          Constants.rpcUrl
        );
        // Initalising contract using rpc provider
        const dcarBurnBattleContractViaRPC =
          getDCARBurnBattleContract(rpcProvider);

        const curRound = await dcarBurnBattleContractViaRPC.currentRound();
        currentRound.value = ethers.utils.formatUnits(curRound, 0);

        const roundDetails = await dcarBurnBattleContractViaRPC.rounds(
          curRound
        );

        totalBurned.value = ethers.utils.formatEther(roundDetails.totalBurned);
        const duration = parseInt(
          ethers.utils.formatUnits(roundDetails.duration, 0)
        );
        roundStartTime.value = ethers.utils.formatUnits(
          roundDetails.startTime,
          0
        );
        roundEndTime.value =
          parseInt(roundStartTime.value) + parseInt(duration);
        console.log(roundEndTime.value);
        const now = new Date();
        if (
          roundStartTime.value === 0 ||
          roundEndTime.value * 1000 < now.getTime()
        ) {
          hasStarted.value = false;
        } else {
          hasStarted.value = true;
        }
        console.log(hasStarted.value);

        await fetchLeaderboard(dcarBurnBattleContractViaRPC); // updating leaderboard
      } catch (error) {
        console.log(error);
      }
    };

    const updateDCARBurnedByYou = async () => {
      const dcarBurnedByYou = await DCARBurnBattleContract.value.roundUserBurns(
        ethers.utils.parseUnits(currentRound.value, 0),
        address.value
      );
      yourBurnAmount.value = ethers.utils.formatEther(dcarBurnedByYou);
    };

    const updateTotalBurned = async () => {
      const rpcProvider = new ethers.providers.JsonRpcProvider(
        Constants.rpcUrl
      );
      const dcarBurnBattleContractViaRPC =
        getDCARBurnBattleContract(rpcProvider);
      const roundDetails = await dcarBurnBattleContractViaRPC.rounds(
        currentRound.value
      );

      totalBurned.value = ethers.utils.formatEther(roundDetails.totalBurned);
    };

    /**
     * Initialising contract instances when signer is available
     */
    const unWatchSessionActive = store.watch(
      () => store.state.isSessionActive,
      async (isSessionActive) => {
        if (isSessionActive) {
          await initialiseContractsAndGetData();
          subscribeToDCARBurn();
        }
      }
    );

    /**
     * Method to verify of DCAR is pre-approved
     */
    const checkIsDCARApproved = async () => {
      let allowanceDCARBattle = await DCARContract.value.allowance(
        address.value,
        DCARBurnBattleContract.value.address
      );
      allowanceDCARBattle = ethers.utils.formatEther(allowanceDCARBattle);
      currentAllowance.value = allowanceDCARBattle;
      console.log("allowance", allowanceDCARBattle);
      if (
        parseFloat(allowanceDCARBattle) >= parseFloat(dcarAmountToBurn.value) && // The market data returns dcar price also as priceindcau. TODO need to fix this later
        parseFloat(allowanceDCARBattle) > 0.0
      ) {
        isDCARApproved.value = true;
        // store.commit("marketplace/setIsDCARApproved", false);
      } else {
        isDCARApproved.value = false;
        // store.commit("marketplace/setIsDCARApproved", true);
      }
    };

    /**
     * Checking if allowance is there or not when changing value.
     */
    const checkAllowanceAgain = async () => {
      if (currentAllowance.value < dcarAmountToBurn.value) {
        await checkIsDCARApproved();
      } else {
        isDCARApproved.value = true;
      }
    };

    const avvyNameForAddress = (address) => {
      store.commit("avvynames/getOrSetAvvyName", address);
      return store.state.avvynames.addressToNames[address];
    };

    /**
     * Subscribing to the Burn Event fired from DCARBattleContract
     * event Burn(address indexed account, uint256 amount, uint256 round);
     */
    const subscribeToDCARBurn = () => {
      try {
        const burnContract = getDCARBurnBattleContract(signer.value);
        burnContract.on("Burn", async (account, amount, round) => {
          const decimalAmount = parseFloat(ethers.utils.formatEther(amount));
          const decimalRound = parseFloat(ethers.utils.formatUnits(round, 0));
          console.log(
            `DCAR AMOUNT BURNED ${decimalAmount} by address ${account} in round ${decimalRound}`
          );
          // Refreshing leaderboard
          await fetchLeaderboard(DCARBurnBattleContract.value);
        });
        console.log("Subscribing to DCAR Burn Event");
      } catch (error) {
        console.log(error);
      }
    };

    /**
     * Fetching leaderboard from the burn dcar contract for the current active round
     */
    const fetchLeaderboard = async (dcarBurnContractInstance) => {
      console.log("Coming here");
      console.log(currentRound.value);
      if (currentRound.value > 0) {
        const topBurners = await dcarBurnContractInstance.getTopBurnersForRound(
          currentRound.value
        );
        leaderboard.value = [...topBurners].reverse();
        leaderboard.value = leaderboard.value.filter(
          (entry) => entry.user !== ethers.constants.AddressZero
        );
        const tempTopBurner = await dcarBurnContractInstance.overallTopBurner();
        overallTopBurner.value = {
          user: tempTopBurner.user,
          amount: ethers.utils.formatEther(tempTopBurner.amount),
        };
        console.log(overallTopBurner.value);
        console.log(leaderboard.value);
      }
    };

    /**
     * Method to return decimal value of big number
     * @param {*} amountInBigNumber
     */
    const amountBurnedInDecimal = (amountInBigNumber) => {
      return ethers.utils.formatEther(amountInBigNumber);
    };

    /**
     * Method to approve DCAR for the DCARBattleBurn contract
     */
    const approveDCAR = async () => {
      isApprovingDCAR.value = true;
      try {
        let txApproveMarketplaceSpend = await DCARContract.value.approve(
          DCARBurnBattleContract.value.address,
          ethers.utils.parseEther("1000000000")
        );
        const receipt = await txApproveMarketplaceSpend.wait();
        if (receipt.status === 1) {
          const allowanceDCARBattle = ethers.utils.formatEther(
            receipt.events[0].args.value
          );
          console.log("Allownace DCAR Battle", allowanceDCARBattle);
          isDCARApproved.value =
            parseFloat(allowanceDCARBattle) >=
            parseFloat(dcarAmountToBurn.value);
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isApprovingDCAR.value = false;
    };

    // Helper method to delay stuff
    const delay = async (time) => new Promise((res) => setTimeout(res, time));

    /**
     * Burning DCAR using the dcar battle contract
     */
    const burnDCAR = async () => {
      isBurningToken.value = true;
      if (dcarAmountToBurn.value > 0) {
        try {
          // Executing Heal Transaction
          const tx = await DCARBurnBattleContract.value.burnTokens(
            ethers.utils.parseEther(`${dcarAmountToBurn.value}`)
          );
          store.commit("setTurnHappening", true);
          playFireSound.play();
          const receipt = await tx.wait();
          // Only process Heal if the transaction was successful!
          if (receipt.status === 1) {
            await fetchLeaderboard(DCARBurnBattleContract.value); // updating leaderboard
            await getDCARBalance(); // updating token balances
          }
          await delay(2000);
          playFireSound.stop();
          store.commit("setTurnHappening", false);
          playTrumpetSound.play();
          store.commit(
            "setNotification",
            `Successfully burned ${dcarAmountToBurn.value} DCAR!`
          );
          await updateDCARBurnedByYou();
          await updateTotalBurned();
        } catch (error) {
          console.log(error);
          store.commit("setNotification", parseError(error));
          store.commit("setTurnHappening", false);
        }
      } else {
        dcarAmountToBurn.value = 1;
        store.commit(
          "setNotification",
          "Please enter a DCAR value more than 0"
        );
        return;
      }
      isBurningToken.value = false;
    };
    const goToGame = () => {
      router.push("/");
    };
    const refresh = () => {
      window.location.reload();
    };

    const isSessionActive = computed(() => {
      return store.state.isSessionActive;
    });

    const isTurnHappening = computed(() => {
      return store.state.isTurnHappening;
    });

    return {
      // data
      isLoading,
      isLoadingInitialData,
      isLoggedIn,
      profileImage,
      dcarBalance,
      dcarAmountToBurn,
      minimumBurn,
      isFetchingDcarBalance,
      isDCARApproved,
      isApprovingDCAR,
      isBurningToken,
      leaderboard,
      zeroAddress,
      overallTopBurner,
      yourBurnAmount,
      address,
      currentRound,
      roundEndTime,
      hasStarted,
      totalBurned,
      showPlayers,
      collapseIt,
      collapseIt2,
      showPage,
      // address,
      // method
      checkAllowanceAgain,
      refresh,
      goToGame,
      burnDCAR,
      approveDCAR,
      amountBurnedInDecimal,
      avvyNameForAddress,
      loadPage,
      // computed
      isSessionActive,
      isTurnHappening,
    };
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;600;700&display=swap");

video.fire {
  opacity: 0;
  /* transform: translateY(100%); */
  transition: all 0.5s ease;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  position: absolute;
  left: 0;
  display: none;
  @media only screen and (max-width: 576px) {
    bottom: 0;
    height: 100vh;
    width: auto;
    object-fit: cover;
    position: fixed;
  }
}
.dark-mode {
  .bg {
    background: #2c1c10 url("../../assets/burning-bg-night.png");
    background-position: top;
    background-attachment: fixed;
    background-size: cover;
  }
}
.bg {
  background: #2c1c10 url("../../assets/burning-bg.png");
  background-position: top;
  background-attachment: fixed;
  background-size: cover;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 1s ease;
  @media only screen and (max-width: 576px) {
    width: 100%;
    height: 100%;
    position: fixed;
  }
}
.animate-fire {
  background: transparent;

  .bg {
    transform: scale(1.5) translate(60px, 100px);
  }
  .white-box {
    opacity: 0;
  }

  video.fire {
    opacity: 0.7;
    display: block;
    /* transform: translateY(0); */
  }
}
.burning-dcar h3 {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-weight: bold;
  position: relative;
  top: 50px;
  .loading {
    background: none;
    width: 25px !important;
    height: 25px;
    margin-right: 20px;
  }
  &:after {
    background: url("../../assets/ui/dcar.png") no-repeat left center;
    background-size: contain;
    content: "";
    display: inline-block;
    width: 46px;
    height: 46px;
    line-height: 0;
    margin-top: -13px;
    padding: 0;
    top: 13px;
    position: relative;
    margin-right: 4px;
  }
}

.balances {
  div {
    display: block;
    align-items: center;
    justify-items: flex-start;
    font-size: 1.4rem;
    margin: 11px 0;
    font-family: "Lato", sans-serif;
    color: #000;
    &:before {
      background-size: contain;
      content: "";
      display: inline-block;
      width: 46px;
      height: 46px;
      line-height: 0;
      margin-top: -13px;
      padding: 0;
      top: 13px;
      position: relative;
      margin-right: 4px;
    }
    p {
      margin: 0;
      span {
        font-size: 0.7rem;
        display: block;
        margin: 2px 0 0;
        color: #ffffff85;
      }
    }
  }
  .dcau {
    &:before {
      background: url("../../assets/ui/dcau.png") no-repeat left center;
    }
  }
  .dcar {
    &:before {
      background: url("../../assets/ui/dcar.png") no-repeat left center;
    }
  }
}

.content {
  padding-top: 200px;
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  /* &.dark-mode {
    &:before {
      display: block;
      content: "";
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #00000069;
    }
  } */
  .grid-overall {
    width: 1200px;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    &.two-col {
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 576px) {
      width: 100%;
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
    .top-burner {
      h4:before {
        content: "";
        width: 25px;
        height: 25px;
        background-image: url("../../assets/crown.svg");
        background-size: contain;
        display: inline-block;
        margin-right: 7px;
        top: 6px;
        position: relative;
        margin-top: -10px;
      }
    }
    .white-box {
      width: 100%;

      .leaderboard-entry {
        font-size: 1.1rem;
      }
      .balances div {
        font-size: 1.2rem;
      }
    }
  }
  .black-box {
    width: 100%;
    margin: 0 auto 1.5rem;
    display: block;
    background: #00000092;
    backdrop-filter: blur(1.3px);
    border-radius: 2px;
    box-shadow: 0 0 2px 2px #00000045;
    transition: all 0.5s ease;
    .grid {
      .col {
        background-color: #ffffff3d;
        color: #fff;
        p {
          color: rgba(255, 255, 255, 0.434);
        }
      }
      h5 {
        color: #fff;
      }
    }
    .leaderboard-entry {
      color: #fff;
    }
    .balances {
      div {
        color: #fff;
      }
    }
    &.rewards {
      width: 100%;
      margin-bottom: 0;
      h1 {
        color: #000;
        @media only screen and (max-width: 576px) {
          font-size: 1.2rem;
        }
      }
      img {
        max-width: 90%;
      }
    }
    &.details {
      width: calc(100% - 2rem);
      padding: 1rem;
      font-size: 3rem;
      font-family: "Cinzel", serif;
      @media only screen and (max-width: 576px) {
        font-size: 1.5rem;
      }
    }
    h4,
    h1 {
      display: block;
      background: rgb(179, 81, 38);
      margin: 0;
      background: linear-gradient(
        90deg,
        rgba(179, 81, 38, 1) 0%,
        rgba(232, 206, 117, 1) 50%,
        rgba(179, 81, 38, 1) 100%
      );
      width: calc(100% - 20px);
      padding: 10px;
      color: #4a1d0e;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 10px;
    padding: 10px;
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }

    h5 {
      margin: 0 0 10px;
      color: #000;
      font-family: "Lato", sans-serif;
    }
    .col {
      background-color: #0000003d;
      padding: 1rem;
      box-shadow: 0 0 2px 1px #0000009c;

      #dcar-amount-to-burn {
        font-size: 1.2rem;
        width: 50%;
        margin: 1px 0;
        padding: 3px;
        border: none;
        border-radius: 5px;
        box-shadow: 0 0 2px 2px #0000003b;
        text-align: center;
      }
      p {
        color: #000000c3;
        font-size: 0.7rem;
        font-family: Lato;
        margin: 6px 0 0;
        position: relative;
      }
    }
  }
  .burn-dcar-button {
    padding: 1rem;
  }
  .white-box {
    width: 100%;
    margin: 0 auto 1.5rem;
    display: block;
    background: #ffffff76;
    backdrop-filter: blur(1.3px);
    border-radius: 2px;
    box-shadow: 0 0 2px 2px #00000045;
    transition: all 0.5s ease;
    @media only screen and (max-width: 576px) {
      width: 100%;
    }
    &.rewards {
      width: 100%;
      margin-bottom: 0;
      h1 {
        color: #000;
        @media only screen and (max-width: 576px) {
          font-size: 1.2rem;
        }
      }
      img {
        max-width: 90%;
      }
    }
    .grid {
      .col {
        background-color: #0000003d;
      }
      h5 {
        color: #000;
      }
    }
    h4,
    h1 {
      display: block;
      background: rgb(179, 81, 38);
      margin: 0;
      background: linear-gradient(
        90deg,
        rgba(179, 81, 38, 1) 0%,
        rgba(232, 206, 117, 1) 50%,
        rgba(179, 81, 38, 1) 100%
      );
      width: calc(100% - 20px);
      padding: 10px;
      color: #4a1d0e;
    }
  }
  .leaderboard-entry {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 1.3rem;
    padding: 5px 0;
    border-bottom: 1px solid #0000004d;
    color: #000;
    align-items: center;
    font-family: "Lato", sans-serif;
    &:nth-child(odd) {
      background-color: #00000017;
    }
    .user {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 5px;
    }
    .balances {
      div:before {
        width: 25px;
        height: 25px;
        line-height: 0;
        margin-top: -5px;
        padding: 0;
        top: 5px;
        background-size: cover;
      }
    }
  }

  .leaderboard-entry.single-column {
    grid-template-columns: 1fr;
  }
  .leaderboard-list {
    .leaderboard-entry {
      grid-template-columns: 50px 1fr 1fr;
      justify-items: center;
      .rank {
        background: #000000a8;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        color: #fff;
        font-size: 1rem;
        display: grid;
        align-items: center;
      }
    }
  }
}

.collapse,
.not-collapsed {
  h1 {
    position: relative;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    &:before {
      font-size: 1.2rem;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.not-collapsed {
  h1 {
    &:before {
      content: "▲";
    }
  }
}
.collapse {
  h1:before {
    content: "▼";
  }
  div {
    display: none;
  }
  .fade-in {
    animation: none;
  }
}
.intro {
  div {
    padding: 1.5rem;
    text-align: center;
  }
  p {
    font-family: Lato;
    font-size: 1.5rem;
  }
}
/* .fade-in {
  opacity: 0;
  animation: fade-in 1s forwards;
  animation-delay: 3.5s;
} */
/* .fade-in-2 {
  opacity: 0;
  animation: fade-in 1s forwards;
  animation-delay: 4s;
} */

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.typing {
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3.5s steps(40, end);
}
.show-scale {
  animation: scale 2s ease-in-out forwards;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  50% {
    border-color: transparent;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
