<template>
  <span>{{ hours }}</span
  >h : <span>{{ minutes }}</span
  >m : <span>{{ seconds }}</span
  >s
</template>

<script>
import { ref, onUnmounted, watch, onMounted } from "vue";
export default {
  name: "CountdownTimer",
  props: {
    duration: {
      // small || extra-small || default large
      type: Number,
      default: 0,
    },
    timeLeft: {
      default: 0,
      type: Number,
    },
  },
  emits: ["timeIsRunningOut", "timeLeft"],
  setup(props, { emit }) {
    const endTime = ref(props.duration * 1000); // Convert duration to milliseconds
    const now = new Date();
    const remainingTime = ref(endTime.value - now.getTime());
    const hours = ref(0);
    const minutes = ref(0);
    const seconds = ref(0);

    onMounted(() => {
      updateFormattedTime();
      if (hours.value > 0 || minutes.value > props.timeLeft) {
        emit("timeLeft");
      } else {
        emit("timeIsRunningOut");
      }
    });

    const checkTimeLeft = () => {
      if (hours.value == 0 && minutes.value <= props.timeLeft) {
        // Set the number of minutes you want to check for
        emit("timeIsRunningOut"); // Emit an event
        // You could also call another method here if needed
      }
    };

    const updateFormattedTime = () => {
      hours.value = Math.floor(
        (remainingTime.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      minutes.value = Math.floor(
        (remainingTime.value % (1000 * 60 * 60)) / (1000 * 60)
      );
      seconds.value = Math.floor((remainingTime.value % (1000 * 60)) / 1000);
    };

    const countdown = () => {
      if (remainingTime.value <= 0) {
        clearInterval(timer);
      } else {
        remainingTime.value -= 1000; // Decrease remaining time by 1 second
        updateFormattedTime();
        checkTimeLeft();
      }
    };

    const timer = setInterval(countdown, 1000);
    onUnmounted(() => clearInterval(timer));

    watch(remainingTime, updateFormattedTime);

    return {
      hours,
      minutes,
      seconds,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";
</style>
